<template>
  <BlocksSection
    element="header"
    class="page-header"
    layout="col-2"
    :background="background || {}"
    :theme="theme || {}"
    :supermask="supermask"
  >
    <AtomsBorder :delay="isDesktop.border" />
    <div class="page-header-content">
      <AtomsHeading
        element="h2"
        :text="heading"
        :delay="isDesktop.heading"
      />
      <LazyAtomsDate
        v-if="datePublished"
        class="page-header-date"
        :delay="isDesktop.date"
        :date="props.datePublished"
      />
      <LazyAtomsList
        v-if="nav.length > 1 || (nav.length && nav[0].slug !== slug)"
        custom-class="page-header-nav"
        :items="nav"
        :delay="isDesktop.list"
      >
        <template #link>
          <SvgoDiamond class="page-header-nav-diamond" />
        </template>
      </LazyAtomsList>
    </div>
    <LazyBlocksImages
      v-if="images"
      class="page-header-images"
      :items="images"
      :delay="isDesktop.images"
    />
    <template v-if="introduction" #after>
      <div class="section-after page-header-intro">
        <LazyAtomsLargeText :delay="isDesktop.intro">{{
          introduction
        }}</LazyAtomsLargeText>
        <LazyAtomsIcon
          name="chevron-down"
          class="page-header-intro-icon"
          :style="{
            animationDelay: (isDesktop.icon) + 's',
          }"
        />
      </div>
    </template>
  </BlocksSection>
</template>

<script lang="ts" setup>
import type { NavItem } from '~~/types'

const props = defineProps({
  slug: String,
  heading: [String, Array],
  background: Object,
  datePublished: String,
  images: Array,
  introduction: String,
  nav: {
    type: Array as () => NavItem[],
    default: () => [],
  },
  theme: Object,
  supermask: {
    type: Boolean,
    default: true,
  },
})

const isDesktop = useState('isDesktop', () => {
  const isDesktop = process.client &&
    !!props.background?.image &&
    window &&
    (window.innerHeight < window.innerWidth || window.innerWidth >= 1024)

  return isDesktop ? {
    border: 2.5,
    heading: 2.75,
    date: 3.25,
    list: 3.5,
    images: 3.5,
    intro: 3.6 ,
    icon: 4 
  } : {
    border: 0,
    heading: 0.25,
    date: 0.75,
    list: 1,
    images: 1,
    intro: 1.1,
    icon: 1.5
  }
})
</script>

<style lang="scss">
.page-header {
  position: relative;
  margin-bottom: 6rem;
  max-height: 100vh;

  &-date {
    margin: 1rem 0.2rem 0;
  }

  .container {
    min-height: 80vh;
    padding-top: 0rem;
    align-items: center;

    @include media('<desktop') {
      align-content: flex-end;
      justify-content: flex-end;
      grid-gap: 0;

      .page-header-content {
        flex-basis: 100%;
        align-self: flex-end;
      }
    }
  }

  @include media('>1200px') {
    .image-background {
      transform: translateX(-3vw);
    }
  }

  .page-header {
    &-content {
      position: relative;
      flex: 1 1 60%;
      z-index: 20;
      margin: 0 0 czn.$lg;

      @include media('<tablet') {
        margin-bottom: 8vh;
      }
      // max-width: var(--character-width) + ch;
    }
    &-intro {
      p {
        width: fit-content;
      }
      &-icon {
        margin: 0 auto;
      }
    }
    &-images {
      flex: 0 1 40%;
      margin-top: 0 !important;
      align-self: flex-end;

      @include media('<tablet') {
        justify-items: flex-end;
        max-width: 28rem;
        flex: 0 1 100%;

        .images-item-1 {
          max-width: 78vw;
        }
      }
    }
    &-nav {
      &-list {
        justify-items: flex-start;
        flex-flow: row wrap;
        font-size: 1.4rem;
        margin: czn.$sm 0 0;
        width: 100%;
      }
      &-diamond {
        transform: rotate(90deg);
        margin-right: 0.5em;
        margin-bottom: 0.15em;
        order: -1;
        width: 0.5em;
      }
      &-item {
        margin: 0;
      }
      &-link {
        padding: czn.$sm czn.$rg;
        color: var(--tertiary);
        &.router-link-exact-active {
          color: var(--primary);
          pointer-events: none;
          opacity: 0.4;
        }

        @include media('<tablet') {
          font-size: 1.1rem;
        }
      }
    }

    .section-background {
      opacity: 0.15;
    }
  }
}
</style>
